@tailwind base;
@tailwind components;
@tailwind utilities;

/* 基础样式覆盖，防止红色文本 */
:root {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  color: #333;
  background-color: #f5f5f5;
}

.error-text {
  color: inherit !important;
}